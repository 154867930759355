import React, { useState, useEffect } from "react";
import { useAuth } from "../component/AuthContext";
import { useNavigate, useParams } from "react-router-dom";
import SweetAlertComponent from "../utils/alert";

const EditPickUpList = () => {
    const { states } = useAuth();
    const [LGAs, setLGAs] = useState([]);
    const [formData, setFormData] = useState({
        state: "",
        LGA: "",
        address: "",
        description: "",
        locationId: "",
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const { setIsSaved } = useAuth();
    const { lga } = useParams();

    // Fetch the pickup station details by ID on component mount
    useEffect(() => {
        const fetchPickupStationDetails = async () => {
            try {
                const token = localStorage.getItem("token");
                const response = await fetch(`https://aino-tech-server.onrender.com/api/v1/settings/get-picklocation-by-lga/${lga}`, {
                    method: "GET",
                    headers: {
                        "Authorization": `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                });

                if (!response.ok) {
                    throw new Error("Failed to fetch pickup station details");
                }

                const location = await response.json();

                // Extract the first location
                if (location.success && location.locations.length > 0) {
                    const data = location.locations[0];
                    setFormData({
                        state: data.state || "",
                        LGA: data.lga || "",
                        address: data.address || "",
                        description: data.description || "",
                        locationId: data._id,
                    });
                } else {
                    throw new Error("No location data available");
                }
            } catch (error) {
                setError(error.message);
            }
        };

        fetchPickupStationDetails();
    }, [lga]);

    // Fetch LGAs based on the selected state
    useEffect(() => {
        if (formData.state) {
            fetchLGAs(formData.state);
        }
    }, [formData.state]);

    const fetchLGAs = async (selectedState) => {
        try {
            const token = localStorage.getItem("token");
            setLoading(true);
            const response = await fetch(`https://aino-tech-server.onrender.com/api/v1/utilities/get-lga-of-state/${selectedState}`, {
                method: "GET",
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            });

            if (!response.ok) {
                throw new Error("Failed to fetch LGAs");
            }

            const data = await response.json();
            setLGAs(data.local_govt || []);
            setLoading(false);
        } catch (error) {
            setError(error.message);
            setLoading(false);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        let sweetAlertClass = new SweetAlertComponent();
        try {
            const token = localStorage.getItem("token");
            const response = await fetch(`https://aino-tech-server.onrender.com/api/v1/settings/edit-pickup-location/${formData.locationId}`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                },
                body: JSON.stringify({
                    state: formData.state,
                    lga: formData.LGA,
                    description: formData.description,
                    address: formData.address,
                }),
            });

            if (!response.ok) {
                const errMessage = (await response.json())?.message || "Failed to update pickup station.";
                sweetAlertClass.showSweetAlert(2, "Error", errMessage);
                setError(errMessage);
                return;
            }

            const result = await response.json();
            setIsSaved(true);
            sweetAlertClass.showSweetAlert(1, "", result.message);
            navigate("/dashboard/pickupstationlist");
        } catch (error) {
            sweetAlertClass.showSweetAlert(2, "Error", error.message || "An error occurred while updating the pickup station.");
            setError(error.message);
        }
    };

    return (
        <div>
            <div id="wrapper">
                <div id="page" className="">
                    <div className="layout-wrap">
                        <div className="section-content-right">
                            <div className="main-content">
                                <div className="main-content-inner">
                                    <div className="main-content-wrap">
                                        <div className="flex items-center flex-wrap justify-between gap20 mb-27">
                                            <h3>Edit an Existing Pick Up Station</h3>
                                            <ul className="breadcrumbs flex items-center flex-wrap justify-start gap10"></ul>
                                        </div>
                                        <div className="wg-box">
                                            <form className="form-new-product form-style-1" onSubmit={handleSubmit}>
                                                <fieldset className="name mb-24">
                                                    <div className="body-title mb-10">Pick Up State</div>
                                                    <select
                                                        className="flex-grow" name="state" value={formData.state} onChange={handleChange} required >
                                                        <option value="" disabled>Select State</option>
                                                        {states?.map((state) => (
                                                            <option key={state} value={state}>
                                                                {state}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </fieldset>
                                                <fieldset className="name mb-24">
                                                    <div className="body-title mb-10">Local Government Area of the Pick Up Station</div>
                                                    <select className="flex-grow" name="LGA" value={formData.LGA} onChange={handleChange} required disabled={!formData.state || loading} >
                                                        <option value="" disabled>Select LGA</option>
                                                        {LGAs.map((lga) => (
                                                            <option key={lga} value={lga}>
                                                                {lga}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </fieldset>
                                                <fieldset className="name">
                                                    <div className="body-title">Pick Up Address<span className="tf-color-1">*</span></div>
                                                    <input type="text" placeholder="Address Name" name="address" value={formData.address} onChange={handleChange} required />
                                                </fieldset>
                                                <fieldset className="name">
                                                    <div className="body-title">Pick Up Description<span className="tf-color-1">*</span></div>
                                                    <input type="text" placeholder="Pick Up Description" name="description" value={formData.description} onChange={handleChange} required />
                                                </fieldset>

                                                <div className="bot">
                                                    <button className="tf-button w208" type="submit" disabled={!formData.state || !formData.LGA || loading}>
                                                        Update Location
                                                    </button>
                                                    <button onClick={() => navigate("/dashboard/pickupstationlist")} className="tf-button w208" type="button">
                                                        Cancel Update
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div className="bottom-page">
                                    <div className="body-text">Copyright © 2024 Aino Tech Global.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditPickUpList;
