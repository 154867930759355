import React, { useState, useEffect } from "react";
import "../App.css";
import "../index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { FaAngleRight, FaRegTrashCan, FaPlus } from "react-icons/fa6";
import { FaChevronLeft } from "react-icons/fa";
import { CiEdit } from "react-icons/ci";
import { useAuth } from "../component/AuthContext";
import SweetAlertComponent from "../utils/alert";
import { Link, useNavigate } from "react-router-dom";
import NoAdminImage from '../images/avatar/pickup.jpg';
import { Modal, Button } from "react-bootstrap";
import LoadingGif from '../images/avatar/aino_loading.gif';


const PickUpStationList = () => {
    const [showProductModal, setShowProductModal] = useState(false); // Modal for product details
    const [selectedPickUpStation, setSelectedPickUpStation] = useState(null);
    const [loading, setLoading] = useState(true);
    const { pickupstation, getPickUpStations } = useAuth();
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchStation = async () => {
          setLoading(true); 
          getPickUpStations(currentPage);
          setLoading(false);
        };
    
        fetchStation();
    }, [currentPage]);

    const handlePreviousPage = () => {
        if (currentPage > 1) setCurrentPage(currentPage - 1);
      };
    
      const handleNextPage = () => {
        if (currentPage < totalPages) setCurrentPage(currentPage + 1);
      };

    const handleShowPickUpModal = (pickupstation) => {
        setSelectedPickUpStation(pickupstation);
        setShowProductModal(true);
    };

    const handleClosePickUpModal = () => {
        setShowProductModal(false);
        setSelectedPickUpStation(null);
    };

    const handleNavigate = (lga) => {
        navigate(`/dashboard/editpickuplist/${lga}`);
    };

    const handleDeleteStation = async () => {
        let sweetAlertClass = new SweetAlertComponent();
        if (!selectedPickUpStation) return;
        const token = localStorage.getItem('token');
        try {
          const response = await fetch(`https://aino-tech-server.onrender.com/api/v1/settings/delete-pickup-location/${selectedPickUpStation._id}`, {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${token}`,
            },
            body: JSON.stringify({ }),
          });
    
          if (!response.ok) {
            sweetAlertClass.showSweetAlert(2, "", `Failed to update product status`);
          }
    
          const deletedStation = await response.json();
          const updatedStation = pickupstation.filter((pickupstation) => pickupstation._id !== selectedPickUpStation._id);
          handleClosePickUpModal();
          getPickUpStations(updatedStation);
          sweetAlertClass.showSweetAlert(1, "", deletedStation.message);
        } catch (error) {
          sweetAlertClass.showSweetAlert(2, "", error.message);
        }
    };

    return (
        <div>
            <div>
                {loading ? (
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center',  textAlign: 'center', height: '100vh' }}>
                        <img src={LoadingGif} alt="Loading" style={{ width: '150px', height: '150px', objectFit: 'cover' }} />
                        <p>Loading All Pick Up Station...</p>
                    </div>
                ) : (
                    <div id="wrapper">
                        <div id="page" className="">
                            <div className="layout-wrap">
                                <div className="section-content-right">
                                    <div className="main-content">
                                        <div className="main-content-inner">
                                            <div className="main-content-wrap">
                                                <div className="flex items-center flex-wrap justify-between gap20 mb-27">
                                                    <h3>All Pick Up Station</h3>
                                                </div>
                                                <div className="wg-box">
                                                    <div className="flex items-center justify-between gap10 flex-wrap">
                                                    <div className="wg-filter flex-grow"></div>
                                                        <Link className="tf-button style-1 w208" to="/dashboard/newpickuplist" >
                                                            <FaPlus size={20} />
                                                            Add Location
                                                        </Link>
                                                    </div>
                                                    <div className="wg-table table-all-category">
                                                        <ul className="table-title flex gap10 mb-14">
                                                            <li>
                                                                <div className="body-title"> Pick Up State</div>
                                                            </li>
                                                            <li>
                                                                <div className="body-title"> Pick Up LGA</div>
                                                            </li>
                                                            <li>
                                                                <div className="body-title"> Pick Up Address </div>
                                                            </li>
                                                            <li>
                                                                <div className="body-title">Pick Up description</div>
                                                            </li>
                                                            <li>
                                                                <div className="body-title">Action</div>
                                                            </li>
                                                        </ul>
                                                        <ul className="flex flex-column">
                                                            {pickupstation.map((pickupstation, index) => (
                                                                <li key={index} className="product-item gap14">
                                                                    <div className="body-text">{(index + 1)}</div>
                                                                    <div className="flex items-center justify-between gap20 flex-grow">
                                                                        <div className="body-text">{pickupstation.state}</div>
                                                                        <div className="body-text">{pickupstation.lga}</div>
                                                                        <div className="body-text">{pickupstation.address}</div>
                                                                        <div className="body-text">{pickupstation.description}</div>
                                                                        <div className="list-icon-function">
                                                                            <div className="item edit" >
                                                                                <CiEdit color="green" onClick={() => handleNavigate(pickupstation.lga)} />
                                                                            </div>
                                                                            <div className="item trash" >
                                                                                <FaRegTrashCan color="red" onClick={() => handleShowPickUpModal(pickupstation)} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            ))}
                                                            {pickupstation?.length === 0 && (
                                                                <li style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                                                                    <img src={NoAdminImage} alt="No Admin Found" style={{ width: '300px', height: '300px', borderRadius: '50%', objectFit: 'cover' }} />
                                                                    <h2>No Pick Up station Yet.</h2>
                                                                </li>
                                                            )}
                                                        </ul>
                                                    </div>
                                                    <div className="divider"></div>
                                                    <div className="flex items-center justify-between flex-wrap gap10">
                                                    <div className="wg-pagination-wrap"></div>
                                                        <ul className="wg-pagination">
                                                            <li><Link to="#" onClick={handlePreviousPage} disabled={currentPage === 1}><FaChevronLeft /></Link></li>
                                                            {[...Array(totalPages)].map((_, index) => (
                                                            <li key={index}>
                                                                <Link to="#" onClick={() => setCurrentPage(index + 1)} style={{ fontWeight: currentPage === index + 1 ? 'bold' : 'normal' }}>
                                                                {index + 1}
                                                                </Link>
                                                            </li>
                                                            ))}
                                                            <li><Link to="#" onClick={handleNextPage} disabled={currentPage === totalPages}><FaAngleRight /></Link></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bottom-page">
                                        <div className="body-text">
                                            Copyright © 2024 Aino Tech Global.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <Modal show={showProductModal} onHide={handleClosePickUpModal}>
                <Modal.Header closeButton>
                <Modal.Title>Pick Up Station Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                {selectedPickUpStation && (
                    <div>
                        <p>Pick Up State: <h4> {selectedPickUpStation.state} </h4></p>
                        <p>Pick Up LGA: <h4> {selectedPickUpStation.lga}</h4></p>
                        <p>Pick Up Address: <h4>{selectedPickUpStation.address}</h4> </p>
                        <p>Pick Up Description: <h4>{selectedPickUpStation.description}</h4></p>
                    </div>
                )}
                    <p>Are You sure you want to delete this Pick Up Location</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClosePickUpModal}>Cancel Delete</Button>
                    <Button variant="danger" onClick={() => handleDeleteStation(!selectedPickUpStation?._id)}>Delete Location</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default PickUpStationList;
