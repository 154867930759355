import React, { createContext, useState, useContext, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import SweetAlertComponent from '../../utils/alert';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [order, setOrder] = useState([]);
  const [sale, setSales] = useState([]);
  const [categories, setCategories] = useState([]);
  const [product, setProduct] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [error, setError] = useState([]);
  const [profile, setProfile] = useState({});
  const [isSaved, setIsSaved] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let sweetAlertClass = new SweetAlertComponent();
    const email = e.target.email.value;
    const password = e.target.password.value;

    try {
      const response = await fetch('https://aino-tech-server.onrender.com/api/v1/vendor/signin', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });
    
      const data = await response.json();
      if (!response.ok) {
        sweetAlertClass.showSweetAlert(2, "Error", data.message);
        throw new Error('Network response was not ok');
      }
  
      if (response.ok) {
        if (data.token.message === "Your email isn't verified. Check your email for OTP") {
          sweetAlertClass.showSweetAlert(2, "Error", data.token.message);
          localStorage.setItem('OTPToken', JSON.stringify(data.token.token));
          return 'notVerified';
        }
        sweetAlertClass.showSweetAlert(1, "Success", data.message);
        localStorage.setItem('vendorData', JSON.stringify(data));
        localStorage.setItem('vendorToken', data.token);
        setIsAuthenticated(true);
        return 'verified';
      }
    } catch (error) {
      console.error('There was a problem with the login request:', error);
    }
  };

  const getProfile = async () => {
    try {
      const vendorToken = localStorage.getItem('vendorToken');
      const response = await fetch(`https://aino-tech-server.onrender.com/api/v1/vendor/vendor-details`, 
        {
          method: 'GET',
          headers: {
            'x-vendor-token': `${vendorToken}`,
            'Content-Type': 'application/json',
          },
        });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setProfile(data);
      setLoading(false);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };

  // categoires
  const categorieslist = async (page) => {
    const vendorToken = localStorage.getItem('vendorToken'); 
    try {
      const response = await fetch('https://aino-tech-server.onrender.com/api/v1/category/${page}/40', 
        {
          method: 'GET',
          headers: {
            'x-vendor-token': `${vendorToken}`,
            'Content-Type': 'application/json',
          },
        });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setCategories(data.categories);
      setTotalPages(data.totalPages);
      setLoading(false);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };
  // Sub Categories 
  const subCategoriesList = async () => {
    const vendorToken = localStorage.getItem('vendorToken');
    try {
      const response = await fetch('https://aino-tech-server.onrender.com/api/v1/category/sub-category/1/30', 
        {
          method: 'GET',
          headers: {
            'x-vendor-token': `${vendorToken}`,
            'Content-Type': 'application/json',
          },
        });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setSubCategories(data.categories);
      setLoading(false);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };

  // get Product
  const getProduct = async () => {
    const vendorToken = localStorage.getItem('vendorToken');
    try {
        const response = await fetch(`https://aino-tech-server.onrender.com/api/v1/vendor/my-products?page=1&limit=5`, {
            method: 'GET',
            headers: {
                'x-vendor-token': `${vendorToken}`,  // Pass the token in the Authorization header
                'Content-Type': 'application/json',
            },
        });
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setProduct(data.products);
        setLoading(false);
    } catch (error) {
        setError(error.message);
        setLoading(false);
    }
  };

  // Order 
  const getOrder = async (pageNum = 1) => {
    const vendorToken = localStorage.getItem('vendorToken');
    try {
        const response = await fetch(`https://aino-tech-server.onrender.com/api/v1/vendor/products-in-orders?page=1&limit=10`, {
            method: 'GET',
            headers: {
                'x-vendor-token': `${vendorToken}`,
                'Content-Type': 'application/json',
            },
        });
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setOrder(data.data.products);
        setSales(data.data);
        setTotalPages(data.data.totalPages);
        setLoading(false);
    } catch (error) {
        setError(error.message);
        setLoading(false);
    }
  };


  

  const merchantlogout = () => {
    localStorage.removeItem("merchantData");
    localStorage.removeItem("token");
    setIsAuthenticated(false);
    window.location.href = "/merchantlogin"; 
  };


  useEffect(() => {
    getProfile();
    categorieslist();
    getProduct();
    getOrder();
    subCategoriesList(); 
  }, [isSaved, currentPage ]);  

  return (
    <AuthContext.Provider value={{
      handleSubmit,
      isAuthenticated,
      merchantlogout,
      subCategories,
      getProduct,
      sale,
      getOrder,
      order,
      product,
      categories,
      setIsSaved,
      error,
      profile,
      loading,
      totalPages
    }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
