import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../App.css";
import "../index.css";
import { FaAngleRight, FaPlus, FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { IoSearchOutline, IoEyeOutline, IoCheckmarkDoneSharp } from "react-icons/io5";
import { Modal, Button , Alert } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../component/AuthContext";
import SweetAlertComponent from "../utils/alert";
import NoAdminImage from '../images/avatar/voucher.jpg';
import LoadingGif from '../images/avatar/aino_loading.gif';
import { ImPriceTags } from "react-icons/im";
import Utlilty from "../utils/func";

const ProductList = () => {
  const navigate = useNavigate();
  const {products, getProduct} = useAuth();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [reasonDisproval, setReason] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showProductModal, setShowProductModal] = useState(false); // Modal for product details
  const [showStatusModal, setShowStatusModal] = useState(false); // Modal for status update
  const [selectedProduct, setSelectedProduct] = useState(null);
  const utility = new Utlilty();

  useEffect(() => {
    const fetchProducts = async () => {
      setLoading(true); // Set loading to true before fetching
      getProduct(currentPage);
      setLoading(false); // Set loading to false after fetching
    };

    fetchProducts();
  }, [currentPage]);

  const handleShowProductModal = (product) => {
    setSelectedProduct(product);
    setShowProductModal(true);
  };  

  const handleCloseProductModal = () => {
    setShowProductModal(false);
    setSelectedProduct(null);
  };

  const handleShowStatusModal = (product, status) => {
    setSelectedProduct({ ...product, isApproved: status });
    setShowStatusModal(true);
  };

  const handleCloseStatusModal = () => {
    setShowStatusModal(false);
    setSelectedProduct(null);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
  };
  const handleNavigate = (id) => {
    if (id) {
      navigate(`/dashboard/editproduct/${id}`);
    } else {
      Alert("Product ID is missing or undefined.");
    }
  };

  const approveProduct = async (productId) => {
    let sweetAlertClass = new SweetAlertComponent();
     
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`https://aino-tech-server.onrender.com/api/v1/admin/inventory/approve-product/${productId}`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${token}`, 
          'Content-Type': 'application/json',
        },
      });
      if (!response.ok) {
        throw new Error('Failed to approve product');
      }
      const result = await response.json();
      sweetAlertClass.showSweetAlert(1, "SuccessFul", result.message);
      getProduct(); 
    } catch (error) {
      sweetAlertClass.showSweetAlert(2, "Error", error.message);
    }
  };

  const disApproveProduct = async (productId) => {
    let sweetAlertClass = new SweetAlertComponent();
    const payload = {
      "reason" : reasonDisproval,
    };
     
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`https://aino-tech-server.onrender.com/api/v1/admin/inventory/decline-product/${productId}`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${token}`, 
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });
      if (!response.ok) {
        throw new Error('Failed to approve product');
      }
      const result = await response.json();
      handleCloseStatusModal();
      getProduct(); 
      sweetAlertClass.showSweetAlert(1, "SuccessFul", result.message);
    } catch (error) {
      sweetAlertClass.showSweetAlert(2, "Error", error.message);
    }
  };

  return (
    <div id="wrapper">
      <div id="page" className="">
        <div className="layout-wrap">
          <div className="section-content-right">
            <div className="main-content">
              <div className="main-content-inner">
                <div className="main-content-wrap">
                  <div className="flex items-center flex-wrap justify-between gap20 mb-27">
                    <h3>Product List</h3>
                    <ul className="breadcrumbs flex items-center flex-wrap justify-start gap10">
                      
                    </ul>
                  </div>
                  <div className="wg-box">
                    <div className="flex items-center justify-between gap10 flex-wrap">
                      <div className="wg-filter flex-grow">
                        <form className="form-search">
                          <fieldset className="name">
                            <input className="mb-10" type="text" placeholder="Search By Product Name" name=""  />
                          </fieldset>
                          <div className="button-submit">
                            <button className="" type="submit">
                              <IoSearchOutline />
                            </button>
                          </div>
                        </form>
                      </div>
                      <Link className="tf-button style-1 w208"
                        to="/dashboard/addproduct" >
                        <FaPlus 
                          size={20} />
                        Add new Inventory
                      </Link>
                    </div>
                    <div className="wg-table-wrap">
                      <div className=" wg-table table-product-list">
                        <ul className="table-title flex gap10 mb-14">
                          <li>
                            <div className="body-title">Product Name</div>
                          </li>
                          <li>
                            <div className="body-title">Product Description</div>
                          </li>
                          <li>
                            <div className="body-title">Price</div>
                          </li>
                          <li>
                            <div className="body-title">Quantity</div>
                          </li>
                          <li>
                            <div className="body-title">Category</div>
                          </li>
                          <li>
                            <div className="body-title">Vendor</div>
                          </li>
                          <li>
                            <div className="body-title">Approved Status</div>
                          </li>
                          <li>
                            <div className="body-title">Date Created</div>
                          </li>
                          <li>
                            <div className="body-title">Action</div>
                          </li>
                        </ul>
                        <ul className="flex flex-column">
                          {loading ? (
                            <li style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                              <img src={LoadingGif} alt="Loading" style={{ width: '200px', height: '200px', objectFit: 'cover' }} />
                              <p>Loading...</p>
                            </li>
                          ) : (
                            <>
                              {Array.isArray(products) && products.length > 0? (
                                products.map((product, index) => (
                                  <li key={product._id} className="product-item gap14">
                                    <div className="body-text">{index + 1}</div>
                                    <div className="image no-bg">
                                      <img src={product.images[0]} alt="" className="" />
                                    </div>
                                    <div className="flex items-center justify-between gap20 flex-grow">
                                      <div className="name">
                                        <Link to="" className="body-title-2">
                                          {product.name}
                                        </Link>
                                      </div>
                                      <div className="body-text">{utility.limitText(product.description, 4)}</div>
                                      <div className="body-text">&#8358; {utility.formatPrice(product.price)}</div>
                                      <div className="body-text">{product.quantity} Pics</div>
                                      <div className="body-text">{product.category?.name}</div>
                                      <div className="body-text">{product.wholesaler ? product.wholesaler.storeName : ""}</div>
                                      <div>
                                        <div className={product.isApproved ? "block-available" : product.isPending ? "block-pending" : "block-not-available"}>
                                          {product.isApproved ? "Approved" : product.isPending ? "Pending-approval" : "Disapproved"}
                                        </div>
                                      </div>
                                      <div className="body-text"> {utility.convertExpirationTime(product.createdAt)}</div>
                                      <div className="list-icon-function">
                                        <div className="item eye" onClick={() => handleShowProductModal(product)}>
                                          <IoEyeOutline color="blue" />
                                        </div>
                                        <div className="item eye" >
                                          <ImPriceTags color="green" onClick={() => {  handleNavigate(product._id)}} />
                                        </div>
                                        {!product.isApproved && (
                                          <div className="dropdown default">
                                            <button
                                              className="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
                                              <span className="item edit">
                                                <IoCheckmarkDoneSharp color="green" />
                                              </span>
                                            </button>
                                            <ul className="dropdown-menu dropdown-menu-end" style={{ backgroundColor: "white" }}>
                                              <li>
                                                <Link to="#" style={{ textDecoration: "none", color: "black" }} onClick={() => { approveProduct(product._id)}} >
                                                  Approve
                                                </Link>
                                              </li>
                                              <li>
                                                <Link to="#" style={{ textDecoration: "none", color: "black" }} onClick={() => handleShowStatusModal(product, false)} >
                                                  Disapprove
                                                </Link>
                                              </li>
                                            </ul>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </li>
                                ))
                              ) : (
                                <li style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                                  <img src={NoAdminImage} alt="No Product Found" style={{ width: '300px', height: '300px', borderRadius: '50%', objectFit: 'cover' }} />
                                  <h2>No Product found.</h2>
                                </li>
                              )}
                            </>
                          )}
                        </ul>
                      </div>
                    </div>
                    <div className="divider"></div>
                    <div className="flex items-center justify-between flex-wrap gap10">
                      <div className="wg-pagination-wrap"></div>
                      <ul className="wg-pagination">
                        <li>
                          <Link to="#" onClick={handlePreviousPage} className="pagination-prev">
                            <FaChevronLeft /> 
                          </Link>
                        </li>
                        <li>
                          <span>{currentPage}</span>
                        </li>
                        <li>
                          <Link to="#" onClick={handleNextPage} className="pagination-next">
                            <FaChevronRight />
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bottom-page">
                <div className="body-text">
                  Copyright © 2024 Aino Tech Global.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* View All Product Modal */}
      <Modal show={showProductModal} onHide={handleCloseProductModal}>
        <Modal.Header closeButton>
          <Modal.Title>Product Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedProduct && (
            <div>
              {selectedProduct.images && (
                <div className="row">
                  {selectedProduct.images.map((img, index) => (
                    <div className="col-md-4" key={index}>
                      <img src={img} alt={`Product ${index}`} className="img-fluid mb-3" />
                    </div>
                  ))}
                </div>
              )}
              <h4>Product Name: {selectedProduct.name}</h4>
              <p>Product Upload By: {selectedProduct.businessDeveloper?.firstName} {selectedProduct.businessDeveloper?.lastName}</p>
              <p>Product ID: {selectedProduct._id}</p>
              <p>Product Price: &#8358; {utility.formatPrice(selectedProduct.price)}</p>
              <p>Vendor Price: &#8358; {utility.formatPrice(selectedProduct.vendorPrice)}</p>
              <p>Product Description: {selectedProduct.description}</p>
              <p>Product Long Description: {selectedProduct.longDesc}</p>
              <p>Product Color: {selectedProduct.color}</p>
              <p>Quantity Available : {selectedProduct.quantity} Pics</p>
              <p>Vendor: {selectedProduct.wholesaler ? selectedProduct.wholesaler.storeName : "N/A"}</p>
              <p>Category: {selectedProduct.category?.name}</p>
              <p>Product Discount: {selectedProduct.discount}</p>
              <p>Product Status: <p className={selectedProduct.isApproved ? "block-available" : "block-not-available"}> {selectedProduct.isApproved ? "Approved" : "Not Approved"} </p> </p>
              <p>Flash Sales : <p className={ selectedProduct.isFlashSale ? "block-available" : "block-not-available"}>{selectedProduct.isFlashSale ? "Yes" : "No"} </p> </p>
              <p>Added By: {selectedProduct.businessDeveloper?.firstName} {selectedProduct.businessDeveloper?.lastName}</p>
              <p>Min Order: {selectedProduct.minOrderQty}</p>
              {selectedProduct.priceRanges && (
                <div>
                  <h3>Retailers Price Ranges</h3>
                  <ul>
                    {selectedProduct.priceRanges.map((range, index) => (
                      <li key={range._id}>
                        <p>{`From ${range.minQuantity}pics to ${range.maxQuantity} pics: `} </p>
                        <p>&#8358;{utility.formatPrice(range.price)}</p>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              {selectedProduct.vendorPriceRanges && (
                <div>
                  <h3>Vendor Retailers Price Ranges</h3>
                  <ul>
                    {selectedProduct.vendorPriceRanges.map((range, index) => (
                      <li key={range._id}>
                        <p>{`From ${range.minQuantity} pics to ${range.maxQuantity} pics: `} </p>
                        <p>&#8358;{utility.formatPrice(range.price)}</p>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              <p>Date Stock:{utility.convertExpirationTime(selectedProduct.createdAt)}</p>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseProductModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal for Approve/Disapprove */}
      <Modal show={showStatusModal} onHide={handleCloseStatusModal}>
        <Modal.Header closeButton>
          <Modal.Title>Reason for Disapproval Of Product</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <fieldset className="description">
              <div className="body-title mb-10">Reason for Disapproval <span className="tf-color-1">*</span></div>
              <textarea className="mb-10" name="reasonDisproval" onChange={(e) => setReason(e.target.value)} value={reasonDisproval}></textarea>
            </fieldset>
          </div>
          <p>Are you sure you want to Disapprove this product?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseStatusModal}> Close </Button>
          <Button variant="danger" onClick={() => { disApproveProduct(selectedProduct._id)}} > Disapprove </Button>
        </Modal.Footer>
      </Modal>

    </div>
  );
};

export default ProductList;