import React, { useState, useEffect } from "react";
import NoAdminImage from '../../images/avatar/voucher.jpg';
import { Link, useNavigate } from "react-router-dom";
import { MdOutlineAdd } from "react-icons/md";
import SweetAlertComponent from "../../utils/alert";
import { Modal, Button, Alert } from "react-bootstrap";
import LoadingGif from '../../images/avatar/aino_loading.gif';
import Utlilty from "../../utils/func";
import { GrEdit } from "react-icons/gr";
import { MdDeleteForever, MdArrowBackIosNew , MdOutlineViewInAr } from "react-icons/md";
import { GrFormNextLink } from "react-icons/gr";

const MerchantProductList = () => {
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [page, setPage] = useState(1);  // Track current page
    const [limit, setLimit] = useState(15); // Number of items per page
    const [totalPages, setTotalPages] = useState(0); // Total number of pages
    const [showProductModal, setShowProductModal] = useState(false); // Modal for product details
    const [showStatusModal, setShowStatusModal] = useState(false); // Modal for status update
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [showViewModal, setShowViewModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const utility = new Utlilty();
    const navigate = useNavigate();

    const fetchProducts = async () => {
        const vendorToken = localStorage.getItem('vendorToken');
        try {
            const response = await fetch(`https://aino-tech-server.onrender.com/api/v1/vendor/my-products?page=${page}&limit=${limit}`, {
                method: 'GET',
                headers: {
                    'x-vendor-token': `${vendorToken}`,  // Pass the token in the Authorization header
                    'Content-Type': 'application/json',
                },
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setProducts(data.products);
            setLoading(false);
        } catch (error) {
            setError(error.message);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchProducts();
    }, [page, limit]);

    const handleNextPage = () => {
        if (page < totalPages) {
            setPage(page + 1);
        }
    };

    const handlePreviousPage = () => {
        if (page > 1) {
            setPage(page - 1);
        }
    };

    const handleShowProductModal = (product) => {
        setSelectedProduct(product);
        setShowViewModal(true);
    };
    
    const handleCloseProductModal = () => {
        setShowViewModal(false);
        setSelectedProduct(null);
    };
    
    const handleShowProductDeleteModal = (product) => {
        setSelectedProduct(product);
        setShowDeleteModal(true);
    };
    
    const handleShowProductDeleteCloseModal = () => {
        setShowDeleteModal(false);
        setSelectedProduct(null);
    };
    

    const handleNavigate = (id) => {
        if (id) {
          navigate(`/merchant/editmerchantproduct/${id}`);
        } else {
          Alert("Product ID is missing or undefined.");
        }
    };

    const handleDeleteProduct = async () => {
        let sweetAlertClass = new SweetAlertComponent();
        if (!selectedProduct) return;
        const vendorToken = localStorage.getItem('vendorToken');
        try {
          const response = await fetch(`https://aino-tech-server.onrender.com/api/v1/vendor/delete-product/${selectedProduct._id}`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                'x-vendor-token': `${vendorToken}`,
            },
            body: JSON.stringify({ }),
          });
    
          if (!response.ok) {
            sweetAlertClass.showSweetAlert(2, "", `Failed to update product status`);
          }
    
          const data = await response.json();
          handleCloseProductModal();
          sweetAlertClass.showSweetAlert(1, "Success", data.message);
          fetchProducts();
        } catch (error) {
          sweetAlertClass.showSweetAlert(2, "Error", error.message);
        }
    };

    return (
        <>
            <main id="main" className="main">

                <div className="pagetitle">
                    <h1>List Of Product On aino Platform</h1>
                    <nav>
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/merchant/merchantdashboard">Dashbaord</Link></li>
                            <li className="breadcrumb-item active">Product List</li>
                        </ol>
                    </nav>
                </div>

                <section className="section">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className=" d-flex justify-content-end mt-5 mb-5">
                                        <Link to="/merchant/addmerchantproduct" type="button" className="btn btn-outline-primary">
                                            <MdOutlineAdd />Add Product
                                        </Link>
                                    </div>

                                    {loading ? (
                                        <span style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                                            <img src={LoadingGif} alt="Loading" style={{ width: '200px', height: '200px', objectFit: 'cover' }} />
                                            <p>Loading Products ...</p>
                                        </span>
                                    ) : (
                                        <>
                                            {products.length > 0 ? (
                                                <table className="table table-striped" style={{ fontSize: '1.5rem' }}>
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">S/N</th>
                                                            <th scope="col">Product Name</th>
                                                            <th scope="col">Products Description</th>
                                                            <th scope="col">Merchant Price</th>
                                                            <th scope="col">Quantity</th>
                                                            <th scope="col">Product Status</th>
                                                            <th scope="col">Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {products.map((product, index) => (
                                                            <tr key={index} style={{ fontSize: '1.5rem' }}>
                                                                <th scope="row" style={{ marginRight: '20px' }}>{index + 1}
                                                                    <span>
                                                                        <img src={product.images[0]} alt="" style={{ width: '70px', height: '70px', objectFit: 'cover' }} />
                                                                    </span>
                                                                </th>
                                                                <td>{product.name}</td>
                                                                <td>{utility.limitText(product.description, 4)}</td>
                                                                <td>&#8358; {utility.formatPrice(product.vendorPrice)}</td>
                                                                <td>{product.quantity} Pics</td>
                                                                <td>
                                                                    <button className={product.isApproved ? "block-available" : product.isPending ? "block-pending" : "block-not-available"}>
                                                                        {product.isApproved ? "Approved" : product.isPending ? "Pending-approval" : "Not Approved"}
                                                                    </button>
                                                                </td>
                                                                <td>
                                                                    <Link to="" type="button" className="btn btn-outline-primary" style={{ marginRight: '10px', fontSize: '1rem' }} onClick={() => handleShowProductModal(product)} > <MdOutlineViewInAr /></Link>
                                                                    <Link to="" type="button" className="btn btn-outline-secondary" style={{ marginRight: '10px', fontSize: '1rem' }} onClick={() => {  handleNavigate(product._id)}} > <GrEdit /></Link>
                                                                    <Link to="" type="button" className="btn btn-outline-danger" onClick={() => handleShowProductDeleteModal(product)} > <MdDeleteForever  /></Link>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            ) : (
                                                <span style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                                                    <img src={NoAdminImage} alt="No Product Found" style={{ width: '300px', height: '300px', borderRadius: '50%', objectFit: 'cover' }} />
                                                    <h2>No Product found on aino Platform.</h2>
                                                </span>
                                            )}
                                            <div className="d-flex justify-content-end mt-4">
                                                {page > 1 && (
                                                    <button className="btn btn-outline-info" onClick={handlePreviousPage}>
                                                        <MdArrowBackIosNew /> Previous
                                                    </button>
                                                )}
                                                <h4>Page {page} </h4>
                                                {page < totalPages && (
                                                    <button className="btn btn-outline-secondary" onClick={handleNextPage}>
                                                        <GrFormNextLink /> Next
                                                    </button>
                                                )}
                                            </div>
                                        </>
                                    )}

                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* View All Product Modal */}
                <Modal show={showViewModal} onHide={handleCloseProductModal}>
                    <Modal.Header closeButton>
                    <Modal.Title>Product Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    {selectedProduct && (
                        <div>
                        {selectedProduct.images && (
                            <div className="row">
                            {selectedProduct.images.map((img, index) => (
                                <div className="col-md-4" key={index}>
                                <img src={img} alt={`Product ${index}`} className="img-fluid mb-3" />
                                </div>
                            ))}
                            </div>
                        )}
                        <h4>Product Name: {selectedProduct.name}</h4>
                        <p>Product ID: {selectedProduct._id}</p>
                        <p>Vendor Price: &#8358; {utility.formatPrice(selectedProduct.vendorPrice)}</p>
                        <p>Product Description: {selectedProduct.description}</p>
                        <p>Product Color: {selectedProduct.color}</p>
                        <p>Quantity Available : {selectedProduct.quantity} Pics</p>
                        <p>Category: {selectedProduct.category?.name}</p>
                        <p>Product Status: <p className={selectedProduct.isApproved ? "block-available" : "block-not-available"}> {selectedProduct.isApproved ? "Approved" : "Not Approved"} </p> </p>
                        <p>Min Order: {selectedProduct.minOrderQty}</p>
                        {selectedProduct.priceRanges && (
                            <div>
                            <h3>Retailers Price Ranges</h3>
                            <ul>
                                {selectedProduct.priceRanges.map((range, index) => (
                                <li key={range._id}>
                                    <p>{`From ${range.minQuantity}pics to ${range.maxQuantity} pics: `} </p>
                                    <p>&#8358;{utility.formatPrice(range.price)}</p>
                                </li>
                                ))}
                            </ul>
                            </div>
                        )}
                        {selectedProduct.vendorPriceRanges && (
                            <div>
                            <h3>Vendor Retailers Price Ranges</h3>
                            <ul>
                                {selectedProduct.vendorPriceRanges.map((range, index) => (
                                <li key={range._id}>
                                    <p>{`From ${range.minQuantity} pics to ${range.maxQuantity} pics: `} </p>
                                    <p>&#8358;{utility.formatPrice(range.price)}</p>
                                </li>
                                ))}
                            </ul>
                            </div>
                        )}
                        <p>Date Stock:{utility.convertExpirationTime(selectedProduct.createdAt)}</p>
                        </div>
                    )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseProductModal}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>

                {/* Delete a product */}
                <Modal show={showDeleteModal} onHide={handleShowProductDeleteCloseModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Delete Product</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {selectedProduct && (
                            <div>
                                {selectedProduct.images && (
                                    <div className="row">
                                        {selectedProduct.images.map((img, index) => (
                                            <div className="col-md-4" key={index}>
                                                <img src={img} alt={`Product ${index}`} className="img-fluid mb-3" />
                                            </div>
                                        ))}
                                    </div>
                                )}
                                <h4>Product Name: {selectedProduct.name}</h4>
                                <p>Product ID: {selectedProduct._id}</p>
                                <p>Vendor Price: &#8358; {utility.formatPrice(selectedProduct.vendorPrice)}</p>
                                <p>Product Description: {selectedProduct.description}</p>
                                <p>Product Color: {selectedProduct.color}</p>
                                <p>Quantity Available: {selectedProduct.quantity} Pics</p>
                                <p>
                                    Product Status: 
                                    <span className={selectedProduct.isApproved ? "block-available" : "block-not-available"}>
                                        {selectedProduct.isApproved ? "Approved" : "Not Approved"}
                                    </span>
                                </p>

                                <p>Min Order: {selectedProduct.minOrderQty}</p>

                                {selectedProduct.priceRanges && (
                                    <div>
                                        <h3>Retailers Price Ranges</h3>
                                        <ul>
                                            {selectedProduct.priceRanges.map((range, index) => (
                                                <li key={range._id}>
                                                    <p>{`From ${range.minQuantity} pics to ${range.maxQuantity} pics:`}</p>
                                                    <p>&#8358;{utility.formatPrice(range.price)}</p>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}

                                {selectedProduct.vendorPriceRanges && (
                                    <div>
                                        <h3>Vendor Retailers Price Ranges</h3>
                                        <ul>
                                            {selectedProduct.vendorPriceRanges.map((range, index) => (
                                                <li key={range._id}>
                                                    <p>{`From ${range.minQuantity} pics to ${range.maxQuantity} pics:`}</p>
                                                    <p>&#8358;{utility.formatPrice(range.price)}</p>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}

                                <p>Date Stock: {utility.convertExpirationTime(selectedProduct.createdAt)}</p>
                                <h1>Are You sure you want to delete this Product?</h1>
                            </div>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleShowProductDeleteCloseModal}>
                            Close
                        </Button>
                        <Button variant="danger" onClick={() => handleDeleteProduct(!selectedProduct?._id)}>
                            Confirm Delete
                        </Button>
                    </Modal.Footer>
                </Modal>
            </main>
        </>
    );
};

export default MerchantProductList;
