import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../App.css";
import "../index.css";
import { FaChevronLeft } from "react-icons/fa6";
import { IoEyeOutline, IoSearchOutline } from "react-icons/io5";
import { useAuth } from "../component/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import NoAdminImage from '../images/avatar/voucher.jpg';
import LoadingGif from '../images/avatar/aino_loading.gif';
import Utility from "../utils/func";
import { FaChevronRight } from "react-icons/fa";
import { GrUpdate } from "react-icons/gr";
import { Modal, Button } from "react-bootstrap";
import SweetAlertComponent from "../utils/alert";

const OrderList = () => {
  const [searchValue, setSearchValue] = useState("");
  const {orderStatus, getAllOrderStatus} = useAuth();
  const {orderCount, getAllOrderCount} = useAuth();
  const [searchTransaction, setSearchTransaction] = useState("");
  const {order, getAllOrders} = useAuth();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedOrderStatus, setSelectedOrderStatus] = useState("");
  const [loading, setLoading] = useState(true);  // Loading state
  const [showModal, setShowModal] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [newStatus, setNewStatus] = useState("");
  const navigate = useNavigate();
  const itemsPerPage = 20;
  const utility = new Utility();
  const [filter, setFilter] = useState("");

  
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); 
      getAllOrders(1, itemsPerPage, filter);
      const totalOrders = await getAllOrderCount();
      const totalPages = Math.ceil(totalOrders / itemsPerPage);
      setTotalPages(totalPages);
      setLoading(false);
    };

    fetchData();
  }, [currentPage, filter]);

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
      getAllOrders(newPage, itemsPerPage, filter); 
    }
  };

  const handleNavigate = (id) => {
    navigate(`/dashboard/orderdetails/${id}`);
  };

  const handleOrderStatusChange = (event) => {
    const newFilter = event.target.value;
    setFilter(newFilter); // Update the filter state
    setSelectedOrderStatus(newFilter);
    getAllOrders(1, itemsPerPage, newFilter);
  };
  

  const handleOpenModal = (order, product) => {
    setSelectedOrder(order);
    setSelectedProduct(product);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedOrder(null);
    setSelectedProduct(null);
    setNewStatus("");
  };

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
    setSearchTransaction(null)
  };

  const handleSearchSubmit = async (event) => {
    event.preventDefault(); // Prevent form from refreshing the page
    if (!searchValue) {
      alert("Please enter a Order ID.");
      return;
    }

    setLoading(true);
    const token = localStorage.getItem("token");
    const sweetAlertClass = new SweetAlertComponent();
    const url = `https://aino-tech-server.onrender.com/api/v1/orders/${searchValue}`;

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch transaction details");
      }

      const data = await response.json();
      setSearchTransaction(data.order); // Set the fetched transaction details
      setLoading(false);
    } catch (error) {
      sweetAlertClass.showSweetAlert(2, "Error", error.message);
      setLoading(false);
    }
  };

  const handleUpdateOrderStatus = async () => {
    let sweetAlertClass = new SweetAlertComponent();
    const token = localStorage.getItem('token');
    if (!selectedOrder || !selectedProduct || !newStatus) return;
    const body = {
      status: newStatus,
      itemId: selectedProduct._id,
    };

    try {
      const response = await fetch(
        `https://aino-tech-server.onrender.com/api/v1/orders/${selectedOrder._id}/status/admin`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(body),
        }
      );

      const data = await response.json();
      if (data.success) {
        sweetAlertClass.showSweetAlert(1, "Success", data.order);
        handleCloseModal();
        getAllOrders(currentPage, itemsPerPage); // Refresh the orders
      } else {
        sweetAlertClass.showSweetAlert(2, "Error", "Failed to update order status");
      }
    } catch (error) {
      sweetAlertClass.showSweetAlert(2, "Error", error.message);
    }
  };


  return (
    <div>
      <div id="wrapper">
        <div id="page">
          <div className="layout-wrap">
            <div className="section-content-right">
              <div className="main-content">
                <div className="main-content-inner">
                  <div className="main-content-wrap">
                    <div className="flex items-center flex-wrap justify-between gap20 mb-27">
                      <h3>Below are list of Order from the App</h3>
                        <ul className="breadcrumbs flex items-center flex-wrap justify-start gap10">
                      </ul>
                    </div>
                    <div className="wg-box">
                    <div className="flex items-center justify-between gap10 flex-wrap">
                      <div className="wg-filter flex-grow">
                        
                      </div>
                    </div>
                    <div className="flex items-center justify-between gap10 flex-wrap">
                      <div className="wg-filter flex-grow">
                        <form className="form-search" onSubmit={handleSearchSubmit}>
                          <fieldset className="name">
                            <input className="mb-10" type="text" placeholder="Search By Order Id" name="" value={searchValue} onChange={handleSearchChange} />
                          </fieldset>
                          <div className="button-submit">
                            <button className="" type="submit">
                              <IoSearchOutline />
                            </button>
                          </div>
                        </form>
                      </div>
                      <fieldset className="tf-button style-1 w208">
                        <select className="flex-grow" aria-required="true" value={selectedOrderStatus} onChange={handleOrderStatusChange}>
                            <option value="" disabled>Select Order Type</option>
                            {orderStatus.map(orderStatusType => (
                                <option key={orderStatusType} value={orderStatusType}>{orderStatusType}</option>
                            ))}
                        </select>
                      </fieldset>
                      </div>
                      <div className="wg-table table-all-category">
                        <ul className="table-title flex gap20 mb-14">
                          <li><div className="body-title">Product</div></li>
                          <li><div className="body-title">User</div></li>
                          <li><div className="body-title">Order ID</div></li>
                          <li><div className="body-title">Total Amount</div></li>
                          <li><div className="body-title">Quantity</div></li>
                          <li><div className="body-title">Payment</div></li>
                          <li><div className="body-title">Order Status</div></li>
                          <li><div className="body-title">Action</div></li>
                        </ul>

                        <ul className="flex flex-column">
                          {loading ? (
                            <li style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                              <img src={LoadingGif} alt="Loading" style={{ width: '200px', height: '200px', objectFit: 'cover' }} />
                              <p>All Orders Loading...</p>
                            </li>
                          ) : ( 
                            (searchValue && searchTransaction ? Object.values([searchTransaction]) : order)?.map((item, orderIndex) => {
                              const productItem = item.products[0]; // Get only the first product

                              return (
                                <li key={orderIndex} className="product-item gap14">
                                  <div className="body-text">{orderIndex + 1}</div>
                                  <div className="image no-bg">
                                    <img src={productItem.product.images[0] || NoAdminImage} alt={productItem.product.name} />
                                  </div>
                                  <div className="flex items-center justify-between gap20 flex-grow">
                                    <div className="name">
                                      <Link to="#" className="body-title-2">
                                        {productItem.product.name}
                                      </Link>
                                    </div>
                                    <div className="body-text">
                                      {item.user?.firstName || " "} {item.user?.lastName || " "}
                                    </div>
                                    <div className="body-text">{item._id}</div>
                                    <div className="body-text"> &#8358; {utility.formatPrice(item.totalAmount)} </div>
                                    <div className="body-text">{productItem.quantity} Pics</div>
                                    <div>
                                      <div className={item.isPaid ? "block-available" : "block-not-available"}>
                                        {item.isPaid ? "True" : "False"}
                                      </div>
                                    </div>
                                    <div className="body-text">{item.status}</div>
                                    <div className="list-icon-function">
                                      <div className="item eye" onClick={() => handleNavigate(item._id)}>
                                        <IoEyeOutline color="blue" />
                                      </div>
                                      <div className="item edit" onClick={() => handleOpenModal(item, productItem)} >
                                        <GrUpdate color="green" />
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              );
                            })
                          )}
                          {order.length === 0 && !loading && (
                            <li style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                              <img src={NoAdminImage} alt="No Order Found" style={{ width: '300px', height: '300px', borderRadius: '50%', objectFit: 'cover' }} />
                              <h2>No Order Found</h2>
                            </li>
                          )}
                        </ul>
                      </div>
                      <div className="divider"></div>
                      <div className="flex items-center justify-between flex-wrap gap10">
                        <div className="wg-pagination-wrap"></div>
                        <ul className="wg-pagination">
                          <li>
                            <Link to="#" onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
                              <FaChevronLeft />
                            </Link>
                          </li>
                          <li>{currentPage}</li>
                          <li>
                            <Link to="#" onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
                              <FaChevronRight />
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> 

      {/* Model to Update Order status */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Update Order Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <p>Order ID: {selectedOrder?._id}</p>
            <p>Product: {selectedProduct?.product.name}</p>
            <select value={newStatus} onChange={(e) => setNewStatus(e.target.value)}>
              <option value="" disabled>
                Select Status
              </option>
              {orderStatus.map((status) => (
                <option key={status} value={status} style={{ fontWeight: "bold" }}>
                  {status}
                </option>
              ))}
            </select>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleUpdateOrderStatus}>
            Update Order Status
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default OrderList;
