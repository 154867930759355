import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../App.css";
import "../index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate, useParams } from 'react-router-dom';
import SweetAlertComponent from "../utils/alert";

const EditCategories = () => {
    const navigate = useNavigate();
    const [name, setName] = useState("");
    const [status, setStatus] = useState("");
    const [ message, setMessage] = useState('');
    const { id } = useParams();

    const handleSubmit = async (e) => {
        let sweetAlertClass = new SweetAlertComponent();
        e.preventDefault();
        const updatedCategory = { name, status };
        const token = localStorage.getItem('token');
        try {
          const response = await fetch(`https://aino-tech-server.onrender.com/api/v1/category/${id}`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify(updatedCategory),
          });
    
          const data = await response.json();
    
          if (response.ok) {
            sweetAlertClass.showSweetAlert(1, "", "Categories Updated Successfully");
            navigate('/dashboard/catergorieslist');
          } else {
            sweetAlertClass.showSweetAlert(2, "", "Error Occurred While Updating Categories");
          }
        } catch (error) {
          setMessage(`Error: ${error.message}`);
        }
    };

  return (
    <div>
      <div id="wrapper">
        <div id="page" className="">
          <div className="layout-wrap">
            
            <div className="section-content-right">
              <div className="main-content">
                <div className="main-content-inner">
                  <div className="main-content-wrap">
                    <div className="flex items-center flex-wrap justify-between gap20 mb-27">
                      <h3> Update Category infomation</h3>
                      <ul className="breadcrumbs flex items-center flex-wrap justify-start gap10">
                  
                      </ul>
                    </div>
                    <div className="wg-box">
                      <form className="form-new-product form-style-1" onSubmit={handleSubmit} >
                        <fieldset className="name">
                          <div className="body-title">Updated Category name <span className="tf-color-1">*</span></div>
                          <input type="text" placeholder="Category Name" name="name" tabIndex="2" onChange={(e) => setName(e.target.value)} value={name} aria-required="true" required />
                        </fieldset>
                        <fieldset className="status">
                          <div className="body-title">Category Status <span className="tf-color-1">*</span> </div>
                            <select value={status} onChange={(e) => setStatus(e.target.value)} required>
                                <option value="">Select Status</option>
                                <option value="active">Active</option>
                                <option value="suspended">Suspended</option>
                            </select>
                        </fieldset>
                        <div className="bot">
                          <div></div>
                          <button className="tf-button w208" type="submit">Update Category</button>
                          <button onClick={() => navigate('/catergorieslist')} className="tf-button w208" type="submit">Cancel Updated</button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="bottom-page">
                  <div className="body-text">Copyright © 2024 Aino Tech Global.</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditCategories;
