import React, { useState, useEffect } from "react";
import "../App.css";
import "../index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { FaAngleRight, FaPlus, FaChevronLeft, FaTrashAlt } from "react-icons/fa";
import { CiEdit } from "react-icons/ci";
import { useAuth } from "../component/AuthContext";
import SweetAlertComponent from "../utils/alert";
import { Link, useNavigate } from "react-router-dom";
import NoAdminImage from '../images/avatar/category.png';
import LoadingGif from '../images/avatar/aino_loading.gif';

const CategoriesList = () => {
  const navigate = useNavigate();
  const { categories, getCategories } = useAuth();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true);
  const [deletingId, setDeletingId] = useState(null);

  useEffect(() => {
    const fetchCategories = async () => {
      setLoading(true); 
      getCategories(currentPage);
      setLoading(false);
    };

    fetchCategories();
  }, [currentPage]);

  const handlePreviousPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const deleteCategory = async (id) => {
    setDeletingId(id);
    const sweetAlertClass = new SweetAlertComponent();
    const token = localStorage.getItem('token');
    try {
      const response = await fetch(`https://aino-tech-server.onrender.com/api/v1/category/${id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });

      if (response.ok) {
        sweetAlertClass.showSweetAlert(1, "", "Category Deleted Successfully");
        const updatedCategories = categories.filter((category) => category._id !== id);
        getCategories(updatedCategories); 
      } else {
        sweetAlertClass.showSweetAlert(2, "", "Error Happened While Deleting Category");
      }
    } catch (error) {
      sweetAlertClass.showSweetAlert(2, "", "Error Occurred While Deleting Category");
    } finally {
      setDeletingId(null);
    }
  };

  const goToEditPage = (id) => {
    navigate(`/dashboard/editcategories/${id}`);
  };

  return (
    <div id="wrapper">
      <div id="page" className="">
        <div className="layout-wrap">
          <div className="section-content-right">
            <div className="main-content">
              <div className="main-content-inner">
                <div className="main-content-wrap">
                  <div className="flex items-center flex-wrap justify-between gap20 mb-27">
                    <h3>All categories</h3>
                    <ul className="breadcrumbs flex items-center flex-wrap gap10">

                    </ul>
                  </div>
                  <div className="wg-box">
                    <div className="flex items-center justify-between gap10">
                      <div className="wg-filter flex-grow"></div>
                      <Link className="tf-button style-1 w208" to="/dashboard/newcategories">
                        <FaPlus size={20} /> Add Category
                      </Link>
                    </div>
                    <div className="wg-table table-all-category">
                      <ul className="table-title flex gap10 mb-14">
                        <li className="body-title">Category Image</li>
                        <li className="body-title">Category Name</li>
                        <li className="body-title">Category ID</li>
                        <li className="body-title">Status</li>
                        <li className="body-title">Action</li>
                      </ul>
                      <ul className="flex flex-column">
                        {categories.length ? categories.map((category, index) => (
                          <li key={category._id} className="product-item gap14">
                            <div className="body-text">{index + 1}</div>
                            <div className="flex items-center gap20 flex-grow">
                              <div className="image">
                                <img src={category.img || NoAdminImage} alt={category.name} style={{ width: '70px', height: '70px' }} />
                              </div>
                              <div className="name">
                                <Link to="#" className="body-title-2">{category.name}</Link>
                              </div>
                              <div className="body-text">{category._id}</div>
                              <div className={category.status === 'active' ? 'block-available' : 'block-not-available'}>
                                {category.status}
                              </div>
                              <div className="list-icon-function">
                                <div className="item edit" onClick={() => goToEditPage(category._id)}>
                                  <CiEdit color="green" />
                                </div>
                                <div className="item delete" onClick={() => deleteCategory(category._id)}>
                                  {deletingId === category._id ? (
                                    <img src={LoadingGif} alt="Loading" style={{ width: '20px', height: '20px' }} />
                                  ) : (
                                    <FaTrashAlt color="red" />
                                  )}
                                </div>
                              </div>
                            </div>
                          </li>
                        )) : (
                          <li className="text-center">
                            <img src={NoAdminImage} alt="No Category Found" style={{ width: '300px', height: '300px', borderRadius: '50%', objectFit: 'cover' }} />
                            <h2>No Category Found</h2>
                          </li>
                        )}
                      </ul>
                    </div>
                    <div className="flex items-center justify-between gap10">
                    <div className="wg-pagination-wrap"></div>
                      <ul className="wg-pagination">
                        <li><Link to="#" onClick={handlePreviousPage} disabled={currentPage === 1}><FaChevronLeft /></Link></li>
                        {[...Array(totalPages)].map((_, index) => (
                          <li key={index}>
                            <Link to="#" onClick={() => setCurrentPage(index + 1)} style={{ fontWeight: currentPage === index + 1 ? 'bold' : 'normal' }}>
                              {index + 1}
                            </Link>
                          </li>
                        ))}
                        <li><Link to="#" onClick={handleNextPage} disabled={currentPage === totalPages}><FaAngleRight /></Link></li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="bottom-page text-center">Copyright © 2024 Aino Tech Global.</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoriesList;
