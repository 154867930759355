import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../App.css";
import "../index.css";
import { Link } from "react-router-dom";
import { FaPeopleArrows } from "react-icons/fa";
import NoAdminImage from '../images/avatar/avatar.png';
import { useAuth } from "../component/AuthContext";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import LoadingGif from '../images/avatar/aino_loading.gif';
import { Modal, Button } from "react-bootstrap";
import SweetAlertComponent from "../utils/alert";

const Referrals = () => {
  const { ref, getAllReferrals } = useAuth();
  const { retailer, getAllRetailer } = useAuth();
  const { admin, getAdmins } = useAuth();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true); 
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [formData, setFormData] = useState({
    admin: "",
    });


  useEffect(() => {
    const fetchReferrals = async () => {
      setLoading(true); // Set loading to true before fetching
      await getAllReferrals(currentPage);
      await getAdmins();
      setLoading(false); // Set loading to false after fetching
    };

    fetchReferrals();
  }, [currentPage ]);

    const handleNextPage = () => {
        if (currentPage < totalPages) {
        setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
        setCurrentPage(currentPage - 1);
        }
    };

    const openModal = (user) => {
        setSelectedUser(user);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedUser(null);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleAssign = async () => {
        let sweetAlertClass = new SweetAlertComponent();
        if (!formData.admin ) {
        //   alert("Please select an admin and a user to assign.");
          sweetAlertClass.showSweetAlert(2, "Error", "Please select an admin and a user to assign.");
          return;
        }
      
        const requestBody = {
            retailerId: formData.admin,
        };
      
        try {
            const token = localStorage.getItem('token');
            const response = await fetch("http://localhost:6969/api/v1/admin/assign-to-retailer", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${token}`,
            },
            body: JSON.stringify(requestBody),
          });
      
            const result = await response.json();
      
            if (response.ok) {
                sweetAlertClass.showSweetAlert(1, "SuccessFul", result.message);
                getAllReferrals(currentPage);
                setIsModalOpen(false);
            } else {
            sweetAlertClass.showSweetAlert(2, "Error", result.message);
            }
        } catch (error) {
            sweetAlertClass.showSweetAlert(2, "Error", error.message);
        }
    };
      
  
    return (
    <div>
        <div id="wrapper">
            <div id="page" className="">
                <div className="layout-wrap">
                    <div className="section-content-right">
                        <div className="main-content">
                            <div className="main-content-inner">
                                <div className="main-content-wrap">
                                    <div className="flex items-center flex-wrap justify-between gap20 mb-27">
                                        <h3>All Referrals</h3>
                                        <ul className="breadcrumbs flex items-center flex-wrap justify-start gap10">
                                        </ul>
                                    </div>
                                    {loading ? (
                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', marginTop: '150px' }}>
                                        <img src={LoadingGif} alt="Loading" style={{ width: '200px', height: '200px', objectFit: 'cover' }} />
                                        <p>All Referrals Loading...</p>
                                    </div>
                                    ) : (
                                    <div className="wg-box">
                                        <div className="wg-table table-all-user">
                                            <ul className="table-title flex gap20 mb-14">
                                                <li><div className="body-title" >Referrals FullName</div></li>
                                                <li><div className="body-title" >Email</div></li>
                                                <li><div className="body-title" >Admin Referral</div></li>
                                                <li><div className="body-title" >Referrals Address</div></li>
                                                <li><div className="body-title">Assign Admin</div></li>
                                            </ul>
                                            <ul className="flex flex-column">
                                                {Array.isArray(ref) && ref.length > 0 ? (
                                                    ref.map((users, index) => (
                                                    <li className="user-item gap14" key={users._id}>
                                                        <div className="">{index + 1}</div>
                                                        <div className="image no-bg">
                                                            <img src={ users?.profileImg || NoAdminImage} alt="" className="" />
                                                        </div>
                                                        <div className="flex items-center justify-between gap20 flex-grow">
                                                            <div className="name">
                                                            <Link to="" className="body-title-2">
                                                                {users?.firstName || "N/A"} {users.lastName || " "}
                                                            </Link>
                                                            </div>
                                                            <div className="body-text">{users?.email || ""}</div>
                                                            <div className="body-text">{users?.adminReferral || ""}</div>
                                                            <div className="body-text">
                                                            {users?.addresses.length > 0 ? `${users.addresses[0].streetAdd}, ${users.addresses[0].city}, ${users.addresses[0].state}` : "No Address"}
                                                            </div>
                                                            <div className="list-icon-function">
                                                            <div className="item eye">
                                                                <FaPeopleArrows color="blue" onClick={() => openModal(users._id)}  style={{ cursor: 'pointer' }}   />
                                                            </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                ))
                                                ) : (
                                                <li style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                                                    <img src={NoAdminImage} alt="No Users Found yet" style={{ width: '300px', height: '300px', borderRadius: '50%', objectFit: 'cover' }} />
                                                    <h2>No Retailers Found yet</h2>
                                                </li>
                                                )}
                                            </ul>
                                        </div>
                                        <div className="divider"></div>
                                        <div className="flex items-center justify-between flex-wrap gap10">
                                            <div className="wg-pagination-wrap"></div>
                                            <ul className="wg-pagination">
                                                <li><Link to="#" onClick={handlePreviousPage} className="pagination-prev" style={{ pointerEvents: currentPage === 1 ? 'none' : 'auto', opacity: currentPage === 1 ? 0.5 : 1 }}><FaChevronLeft /></Link></li>
                                                <li><span>{currentPage}</span></li>
                                                <li><Link to="#" onClick={handleNextPage} className="pagination-next" style={{ pointerEvents: currentPage === totalPages ? 'none' : 'auto', opacity: currentPage === totalPages ? 0.5 : 1 }}><FaChevronRight /></Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                    )}
                                    </div>
                                </div>
                                <div className="bottom-page">
                                    <div className="body-text">Copyright © 2024 Aino Tech Global.</div>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        {/* Model to Assign Referrals */}
        <Modal show={isModalOpen} onHide={closeModal} centered>
            <Modal.Header closeButton>
                <Modal.Title>Assigned Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p><strong>Name:</strong> {selectedUser?.firstName} {selectedUser?.lastName}</p>
                <p><strong>Email:</strong> {selectedUser?.email}</p>
                <p><strong>Phone Number:</strong> {selectedUser?.phoneNumber}</p>
                <p><strong>Address:</strong> {selectedUser?.addresses?.length > 0 ? `${selectedUser.addresses[0].streetAdd}, ${selectedUser.addresses[0].city}, ${selectedUser.addresses[0].state}` : "No Address"}</p>
                <fieldset className="name mb-24">
                    <div className="body-title mb-10">Select Admin to Assign to Retailers</div>
                    <select className="flex-grow" name="admin"  aria-required="true" required>
                        <option value="" disabled>Select Admin to Assign</option>
                        {retailer.map(r => (
                            <option key={r._id} value={r._id}>{r.firstName}{r.lastName}</option>
                        ))}
                    </select>
                </fieldset>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={closeModal}>Close</Button>
                <Button variant="primary" onClick={handleAssign} >Assign</Button>
            </Modal.Footer>
        </Modal>
    </div>
  );
};

export default Referrals;
